<template>
  <v-app>
    <v-main>
      <v-app-bar>
        <v-btn icon :ripple="false">
          <v-icon>mdi-newspaper</v-icon>
        </v-btn>
        <v-toolbar-title>Comedia</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon href="https://app.falcon.io" target="_blank">
          <v-icon>mdi-twitter</v-icon>
        </v-btn>
        <v-btn icon href="https://www.linkedin.com/school/3883/admin" target="_blank">
          <v-icon>mdi-linkedin</v-icon>
        </v-btn>
        <v-btn icon href="https://business.facebook.com/latest/composer"
          target="_blank">
          <v-icon>mdi-facebook</v-icon>
        </v-btn>
        <v-btn icon href="https://actu.epfl.ch" target="_blank">
          <v-icon>mdi-newspaper-variant-outline</v-icon>
        </v-btn>
        <v-btn icon href="https://go.epfl.ch/profile" target="_blank">
          <v-icon>mdi-link-box-outline</v-icon>
        </v-btn>
        <v-btn icon href="https://chat.openai.com/" target="_blank">
          <v-icon>mdi-robot-excited-outline</v-icon>
        </v-btn>
        <v-btn icon href="https://t.me/+55if0EkuNPE3MTI0" target="_blank">
          <v-icon>mdi-message-reply-outline</v-icon>
        </v-btn>
        <v-btn icon @click="generateConfig">
          <v-icon>mdi-share-all-outline</v-icon>
        </v-btn>
        <v-btn icon @click="openSettings = true">
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container>

        <v-card v-if="this.fetchedNewsFR" color="#3700b3" theme="dark" class="mb-3">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="text-h5">
                {{ this.fetchedNewsFR?.title || "Titre de l'article" }}
              </v-card-title>

              <v-card-actions>
                <v-chip class="ms-2" variant="outlined">
                  {{ new Date(this.fetchedNewsFR?.publish_date).toLocaleString("fr-CH", {
          weekday: "long", day: "numeric",
          month: "long", year: "numeric", hour: "numeric", minute: "numeric"
        }) }}
                </v-chip>
                <v-chip class="ms-2" variant="outlined">
                  {{ this.fetchedNewsFR?.channel?.name.toUpperCase() }}
                </v-chip>
                <v-chip class="ms-2" variant="outlined">
                  {{ this.fetchedNewsFR?.category?.fr_label }}
                </v-chip>
                <v-chip class="ms-2" variant="outlined">
                  {{ this.fetchedNewsFR?.authors[0]?.first_name + ' ' + this.fetchedNewsFR?.authors[0]?.last_name }}
                </v-chip>
              </v-card-actions>

              <v-card-actions class="mt-n5">
                <v-btn class="ms-2" variant="outlined" size="small" v-bind:href="this.fetchedNewsFR?.news_url"
                  target="_blank">
                  Vers l'article FR
                </v-btn>
                <v-btn class="ms-2" variant="outlined" size="small" v-bind:href="this.fetchedNewsEN?.news_url"
                  target="_blank">
                  Vers l'article EN
                </v-btn>
                <v-btn disabled class="ms-2" variant="outlined" size="small">
                  Génerer le résumé de l'article
                </v-btn>
              </v-card-actions>
            </div>


            <v-img height="150" cover v-bind:src="this.fetchedNewsFR?.thumbnail_url"></v-img>

          </div>
        </v-card>

        <v-row>
          <v-col cols="12" sm="3">
            <v-sheet>
              <v-container>
                <v-text-field v-model="newsItemProvided" label="Lien actu.epfl.ch ou ID"
                  variant="outlined"></v-text-field>
                <v-btn block @click="fetchNews" :loading="fetchNewsLoader" color="primary" class="mb-10">Chercher
                  l'article</v-btn>

                <v-btn block @click="generateLinks" :loading="generateLinksLoader" color="primary">Génerer les
                  liens</v-btn><br>
                <v-text-field v-model="generatedLinkFR" label="Lien vers l'article FR"
                  variant="outlined"></v-text-field>
                <v-text-field v-model="generatedLinkEN" label="Lien vers l'article EN"
                  variant="outlined"></v-text-field>
                <v-btn block @click="generateLinkFR(this.generateAlias())" variant="outlined" class="mb-5">Regénerer
                  FR</v-btn>
                <v-btn block @click="generateLinkEN(this.generateAlias())" variant="outlined" class="mb-10">Regénerer
                  EN</v-btn>
                <v-tooltip text="Active le prompt 1 de l'IA pour les 3 textes d'un coup" location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn block color="primary" class="mb-3" @click="triggerGPTRequest(null, 0)"
                      :loading="ImFeellingLuckyLoader" v-bind="props">I'm
                      Feeling
                      Lucky</v-btn>
                  </template>
                </v-tooltip>
                <v-btn block @click="openGPTSettings = true" variant="outlined">Configurer GPT</v-btn>
                <v-btn block @click="completeSchedule" color="primary" class="mt-10">Ajouter au
                  schedule</v-btn>
              </v-container>
            </v-sheet>
          </v-col>

          <v-col cols="12" sm="9">
            <v-sheet>
              <v-container>
                <!-- <tweet-box v-model="textForTwitterFR" /> -->
                <v-textarea counter clearable auto-grow prepend-icon="mdi-twitter" v-model="textForTwitterFR"
                  label="Twitter FR" variant="outlined"></v-textarea>
                <div class="ml-10 mb-10">
                  <v-btn size="small" :loading="GPTPromptTwitterFRLoader" variant="outlined" class="mr-2"
                    @click="triggerGPTRequest('TwitterFR', 0)">AI Config 1</v-btn>
                  <v-btn size="small" :loading="GPTPromptTwitterFRLoader" variant="outlined" class="mr-2"
                    @click="triggerGPTRequest('TwitterFR', 1)">AI Config 2</v-btn>
                  <v-btn size="small" variant="outlined" @click="generateTextTwitterFR">Réinitialiser</v-btn>
                </div>
                <v-textarea counter clearable auto-grow prepend-icon="mdi-twitter" v-model="textForTwitterEN"
                  label="Twitter EN" variant="outlined"></v-textarea>
                <div class="ml-10 mb-10">
                  <v-btn size="small" :loading="GPTPromptTwitterENLoader" variant="outlined" class="mr-2"
                    @click="triggerGPTRequest('TwitterEN', 0)">AI Config 1</v-btn>
                  <v-btn size="small" :loading="GPTPromptTwitterENLoader" variant="outlined" class="mr-2"
                    @click="triggerGPTRequest('TwitterEN', 1)">AI Config 2</v-btn>
                  <v-btn size="small" variant="outlined" @click="generateTextTwitterEN">Réinitialiser</v-btn>
                </div>
                <v-textarea counter clearable auto-grow prepend-icon="mdi-linkedin" v-model="textForLinkedin"
                  label="Linkedin / Facebook" variant="outlined"></v-textarea>
                <div class="ml-10 mb-10">
                  <v-btn size="small" :loading="GPTPromptLinkedinLoader" variant="outlined" class="mr-2"
                    @click="triggerGPTRequest('Linkedin', 0)">AI Config 1</v-btn>
                  <v-btn size="small" :loading="GPTPromptLinkedinLoader" variant="outlined" class="mr-2"
                    @click="triggerGPTRequest('Linkedin', 1)">AI Config 2</v-btn>
                  <v-btn size="small" variant="outlined" @click="generateTextLinkedin">Réinitialiser</v-btn>
                </div>
              </v-container>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- Snackbar -->
    <template>
      <v-snackbar v-model="snackbarVisible" :color="snackbarColor" :timeout="snackbarTimeout">
        {{ snackbarText }}
      </v-snackbar>
    </template>

    <!-- Global settings -->
    <template>
      <div class="text-center">
        <v-dialog v-model="openSettings" width="1024">
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-card class="text-justify" title="Bienvenue dans Comedia"
                      subtitle="v.2.2 - fait avec ❤️ par @pmdlt" variant="outlined"
                      text="Comedia (verlan de Mediacom) est une application web conçue pour assister et simplifier la publication des actualités quotidiennes de l'EPFL sur les réseaux sociaux. Grâce à l'intégration d'une IA basée sur OpenAI, Comedia permet de rédiger rapidement des introductions d'articles captivants. D'ailleurs ce texte lui-même a été généré par l'IA.
                      L'application nécessite que l'utilisateur fournisse certaines clés d'API pour accéder à toutes ses fonctionnalités. Ces clés doivent être configurées dans les paramètres de l'application avant de l'utiliser pleinement."></v-card>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="keyGoEPFL" label="Clé API go.epfl.ch" variant="outlined"></v-text-field>
                    <v-text-field v-model="keyOpenAI" label="Clé API OpenAI" variant="outlined"></v-text-field>
                    <v-text-field v-model="macroUrl" label="URL de la macro Google Sheet"
                      variant="outlined"></v-text-field>
                    <!-- <v-divider class="mb-13 mt-7"></v-divider> -->
                    <v-text-field class="mt-1" v-model="newsSearchLimit" label="Limite des recherches actu.epfl.ch"
                      variant="outlined"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" variant="text" @click="saveConfig">
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>

    <!-- GPT settings -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="openGPTSettings" fullscreen :scrim="false" transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="openGPTSettings = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Configuration OpenAI GPT</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn variant="text" @click="saveConfigGPT">
                  Enregistrer
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-container>
              <div class="text-overline text-center">Les prompts ont toujours le contexte de l'article dans la langue
                choisie.</div>

              <v-row>
                <v-col cols="12" sm="6">
                  <div class="ml-10 mb-5">
                    <v-btn block @click="resetGPTConfig(0)" variant="outlined">Config 1 - Réinitialiser par
                      défaut</v-btn>
                  </div>
                  <v-textarea auto-grow prepend-icon="mdi-twitter" v-model="GPTPromptTwitterFR[0]"
                    label="Prompt GPT version 1 pour Twitter FR" variant="outlined"></v-textarea>
                  <v-textarea auto-grow prepend-icon="mdi-twitter" v-model="GPTPromptTwitterEN[0]"
                    label="Prompt GPT version 1 pour Twitter EN" variant="outlined"></v-textarea>
                  <v-textarea auto-grow prepend-icon="mdi-linkedin" v-model="GPTPromptLinkedin[0]"
                    label="Prompt GPT version 1 pour Linkedin / Facebook" variant="outlined"></v-textarea>

                  <div class="ml-10 mb-5">
                    <div class="text-overline">Paramètres de l'IA</div>
                    <div class="text-caption">Selectionnez la version GPT que vous souhaitez utiliser</div>
                    <v-radio-group inline v-model="GPTModel[0]">
                      <v-radio label="4o-mini" value="gpt-4o-mini"></v-radio>
                      <v-radio label="4o" value="gpt-4o"></v-radio>
                      <v-radio label="4" value="gpt-4"></v-radio>
                    </v-radio-group>
                    <div class="text-caption">Temperature</div>
                    <v-slider v-model="GPTTemperature[0]" min="0" max="2" step="0.01" thumb-label></v-slider>
                    <div class="text-caption">Max Tokens</div>
                    <v-slider v-model="GPTMaxTokens[0]" min="1" max="4095" step="1" thumb-label></v-slider>
                    <div class="text-caption">Top P</div>
                    <v-slider v-model="GPTTopP[0]" min="0" max="1" step="0.01" thumb-label></v-slider>
                    <div class="text-caption">Frequency Penalty</div>
                    <v-slider v-model="GPTFrequencyPenalty[0]" min="0" max="2" step="0.01" thumb-label></v-slider>
                    <div class="text-caption">Presence Penalty</div>
                    <v-slider v-model="GPTPresencePenalty[0]" min="0" max="2" step="0.01" thumb-label></v-slider>
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="ml-10 mb-5">
                    <v-btn block @click="resetGPTConfig(1)" variant="outlined">Config 2 - Réinitialiser par
                      défaut</v-btn>
                  </div>
                  <v-textarea auto-grow prepend-icon="mdi-twitter" v-model="GPTPromptTwitterFR[1]"
                    label="Prompt GPT version 2 pour Twitter FR" variant="outlined"></v-textarea>
                  <v-textarea auto-grow prepend-icon="mdi-twitter" v-model="GPTPromptTwitterEN[1]"
                    label="Prompt GPT version 2 pour Twitter EN" variant="outlined"></v-textarea>
                  <v-textarea auto-grow prepend-icon="mdi-linkedin" v-model="GPTPromptLinkedin[1]"
                    label="Prompt GPT version 2 pour Linkedin / Facebook" variant="outlined"></v-textarea>

                  <div class="ml-10 mb-5">
                    <div class="text-overline">Paramètres de l'IA</div>
                    <div class="text-caption">Selectionnez la version GPT que vous souhaitez utiliser</div>
                    <v-radio-group inline v-model="GPTModel[1]">
                      <v-radio label="4o-mini" value="gpt-4o-mini"></v-radio>
                      <v-radio label="4o" value="gpt-4o"></v-radio>
                      <v-radio label="4" value="gpt-4"></v-radio>
                    </v-radio-group>
                    <div class="text-caption">Temperature</div>
                    <v-slider v-model="GPTTemperature[1]" min="0" max="2" step="0.01" thumb-label></v-slider>
                    <div class="text-caption">Max Tokens</div>
                    <v-slider v-model="GPTMaxTokens[1]" min="1" max="4095" step="1" thumb-label></v-slider>
                    <div class="text-caption">Top P</div>
                    <v-slider v-model="GPTTopP[1]" min="0" max="1" step="0.01" thumb-label></v-slider>
                    <div class="text-caption">Frequency Penalty</div>
                    <v-slider v-model="GPTFrequencyPenalty[1]" min="0" max="2" step="0.01" thumb-label></v-slider>
                    <div class="text-caption">Presence Penalty</div>
                    <v-slider v-model="GPTPresencePenalty[1]" min="0" max="2" step="0.01" thumb-label></v-slider>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-app>
</template>

<script>
// import TweetBox from '@/components/TweetBox.vue';
import LZString from 'lz-string';
const { Configuration, OpenAIApi } = require("openai");
const defaultGPTPromptTwitterFR = ["Tu es le responsable des réseaux sociaux de l'EPFL. Quand on te donne un article, tu réponds avec une idée de post pour cet article pour Twitter.\nTa langue de réponse est le Français.", null];
const defaultGPTPromptTwitterEN = ["You are the social media manager of EPFL. When you are given an article, you respond with an idea of a post for this article for Twitter.\nYour response language is English.", null];
const defaultGPTPromptLinkedin = ["Tu es le responsable des réseaux sociaux de l'EPFL. Quand on te donne un article, tu réponds avec une idée de post pour cet article pour Linkedin.\nTa langue de réponse est le Français, puis tu fais la traduction en anglais.", null];
const defaultGPTModel = ["gpt-4o-mini", "gpt-4o-mini"];
const defaultGPTTemperature = [1, 1];
const defaultGPTMaxTokens = [256, 256];
const defaultGPTTopP = [1, 1];
const defaultGPTFrequencyPenalty = [0, 0];
const defaultGPTPresencePenalty = [0, 0];

const baseUrlGoEPFLAPI = "https://comedia-cors-proxy.pmdlt.workers.dev/?https://go.epfl.ch/api/v1/alias";
const baseUrlActuEPFLAPI = "https://comedia-cors-proxy.pmdlt.workers.dev/?https://actu.epfl.ch/api/v1/news/";

export default {

  name: 'App',
  /* components: {
    TweetBox,
  }, */

  data: () => ({
    configSaved: localStorage.getItem('configSaved') || false,
    keyGoEPFL: localStorage.getItem('keyGoEPFL'),
    keyOpenAI: localStorage.getItem('keyOpenAI'),
    macroUrl: localStorage.getItem('macroUrl'),
    openSettings: false,
    openGPTSettings: false,
    snackbarVisible: false,
    snackbarText: '',
    snackbarColor: '',
    snackbarTimeout: 4000,
    newsItemProvided: null,
    newsSearchLimit: 30,
    generatedLinkFR: '[LIEN FR ICI]',
    generatedLinkEN: '[LINK EN HERE]',
    fetchedNewsFR: null,
    fetchedNewsEN: null,
    textForTwitterFR: null,
    textForTwitterEN: null,
    textForLinkedin: null,
    fetchNewsLoader: false,
    generateLinksLoader: false,
    ImFeellingLuckyLoader: false,
    GPTPromptTwitterFRLoader: false,
    GPTPromptTwitterENLoader: false,
    GPTPromptLinkedinLoader: false,
    GPTPromptTwitterFR: JSON.parse(localStorage.getItem('GPTPromptTwitterFR')) || defaultGPTPromptTwitterFR,
    GPTPromptTwitterEN: JSON.parse(localStorage.getItem('GPTPromptTwitterEN')) || defaultGPTPromptTwitterEN,
    GPTPromptLinkedin: JSON.parse(localStorage.getItem('GPTPromptLinkedin')) || defaultGPTPromptLinkedin,
    GPTModel: JSON.parse(localStorage.getItem('GPTModel')) || defaultGPTModel,
    GPTTemperature: JSON.parse(localStorage.getItem('GPTTemperature')) || defaultGPTTemperature,
    GPTMaxTokens: JSON.parse(localStorage.getItem('GPTMaxTokens')) || defaultGPTMaxTokens,
    GPTTopP: JSON.parse(localStorage.getItem('GPTTopP')) || defaultGPTTopP,
    GPTFrequencyPenalty: JSON.parse(localStorage.getItem('GPTFrequencyPenalty')) || defaultGPTFrequencyPenalty,
    GPTPresencePenalty: JSON.parse(localStorage.getItem('GPTPresencePenalty')) || defaultGPTPresencePenalty,
  }),
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const newsId = urlParams.get('id');
    const triggerSchedule = urlParams.get('schedule');
    const config = urlParams.get('config');

    if (newsId) {
      this.newsItemProvided = newsId;
      this.fetchNews().then(() => {
        if (config) {
          const configDecompressed = JSON.parse(LZString.decompressFromEncodedURIComponent(config))
          console.log(configDecompressed)
          if (configDecompressed) {
            this.generatedLinkFR = configDecompressed.generatedLinkFR
            this.generatedLinkEN = configDecompressed.generatedLinkEN
            this.textForTwitterFR = configDecompressed.textForTwitterFR
            this.textForTwitterEN = configDecompressed.textForTwitterEN
            this.textForLinkedin = configDecompressed.textForLinkedin
            this.showSnackbar('Configuration chargée depuis l\'URL.', 'success')
          } else {
            this.showSnackbar('Erreur en décompressant la configuration. Veuillez regarder les logs.', 'error')
          }
        }


        if (triggerSchedule) {
          this.completeSchedule();
        }
      });
    }
    if (!this.configSaved) {
      this.openSettings = true;
    }
  },
  methods: {
    saveConfig() {
      localStorage.setItem('configSaved', true);
      localStorage.setItem('keyGoEPFL', this.keyGoEPFL);
      localStorage.setItem('keyOpenAI', this.keyOpenAI);
      localStorage.setItem('macroUrl', this.macroUrl);
      this.openSettings = false;
    },
    saveConfigGPT() {
      localStorage.setItem('GPTPromptTwitterFR', JSON.stringify(this.GPTPromptTwitterFR));
      localStorage.setItem('GPTPromptTwitterEN', JSON.stringify(this.GPTPromptTwitterEN));
      localStorage.setItem('GPTPromptLinkedin', JSON.stringify(this.GPTPromptLinkedin));
      localStorage.setItem('GPTModel', JSON.stringify(this.GPTModel));
      localStorage.setItem('GPTTemperature', JSON.stringify(this.GPTTemperature));
      localStorage.setItem('GPTMaxTokens', JSON.stringify(this.GPTMaxTokens));
      localStorage.setItem('GPTTopP', JSON.stringify(this.GPTTopP));
      localStorage.setItem('GPTFrequencyPenalty', JSON.stringify(this.GPTFrequencyPenalty));
      localStorage.setItem('GPTPresencePenalty', JSON.stringify(this.GPTPresencePenalty));
      this.openGPTSettings = false;
    },
    showSnackbar(text, color) {
      this.snackbarText = text
      this.snackbarColor = color
      this.snackbarVisible = true
      console.log(text)
    },
    /////// LINK GENERATION ///////
    async generateLinkFR(alias) {
      if (!this.fetchedNewsFR) {
        this.showSnackbar('Veuillez charger une actualité avant de générer un lien.', 'error')
        return
      }
      this.generatedLinkFR = await this.createLink(this.fetchedNewsFR.news_url, alias.concat('-fr'));
    },
    async generateLinkEN(alias) {
      if (!this.fetchedNewsEN) {
        this.showSnackbar('Veuillez charger une actualité avant de générer un lien.', 'error')
        return
      }
      this.generatedLinkEN = await this.createLink(this.fetchedNewsEN.news_url, alias.concat('-en'));
    },
    async generateLinks() {
      if (!this.fetchedNewsFR || !this.fetchedNewsEN) {
        this.showSnackbar('Veuillez charger une actualité avant de générer des liens.', 'error')
        return
      }
      this.generateLinksLoader = true;
      const alias = this.generateAlias()
      await Promise.all([this.generateLinkFR(alias), this.generateLinkEN(alias)])
      this.generateTextFromNews() // TODO : ne faire que si pas modifié
      this.generateLinksLoader = false;
    },
    async createLink(url, alias) {
      if (!this.keyGoEPFL || this.keyGoEPFL == 'null') {
        this.showSnackbar('Veuillez renseigner une clé API go.epfl.ch avant de générer des liens.', 'error')
        return
      }
      const data = {
        token: this.keyGoEPFL,
        alias: alias,
        url: url
      }
      return fetch(baseUrlGoEPFLAPI, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(r => {
          if (r.status != "created") {
            this.showSnackbar('Erreur en générant le lien. Veuillez regarder les logs.', 'error')
            console.error(r)
            return
          }
          this.showSnackbar('La génération de lien s\'est déroulée avec succès', 'success')
          return r.url
        })
        .catch((error) => {
          this.showSnackbar("go.epfl.ch ne répond pas.", 'error')
          console.error(error)
        })
    },
    generateAlias() {
      var result = '';
      var characters = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      var charactersLength = characters.length;
      for (var i = 0; i < 3; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      console.log("Alias generated: ", result)
      return result;
    },
    /////// TEXT GENERATION FROM NEWS ///////
    generateTextFromNews() {
      this.generateTextTwitterFR();
      this.generateTextTwitterEN();
      this.generateTextLinkedin();
    },
    generateTextTwitterFR() {
      if (!this.fetchedNewsFR) {
        this.showSnackbar('Veuillez charger une actualité avant de réinitialiser.', 'error')
        return
      }
      this.textForTwitterFR = this.removeTags(this.fetchedNewsFR.subtitle + '\n' + this.generatedLinkFR)
    },
    generateTextTwitterEN() {
      if (!this.fetchedNewsFR) {
        this.showSnackbar('Veuillez charger une actualité avant de réinitialiser.', 'error')
        return
      }
      this.textForTwitterEN = this.removeTags(this.fetchedNewsEN.subtitle + '\n' + this.generatedLinkEN)
    },
    generateTextLinkedin() {
      if (!this.fetchedNewsFR) {
        this.showSnackbar('Veuillez charger une actualité avant de réinitialiser.', 'error')
        return
      }
      this.textForLinkedin = this.removeTags(this.fetchedNewsEN.subtitle + '\n\nRead more: ' + this.generatedLinkEN + '\n\n----------\n\n' + this.fetchedNewsFR.subtitle + '\n\nArticle en français : ' + this.generatedLinkFR)
    },
    removeTags(string) {
      return string.replace(/(<([^>]+)>)/gi, "");
    },

    /////// NEWS FETCHING ///////
    async fetchNews() {
      this.fetchNewsLoader = true
      if (!this.newsItemProvided) {
        this.showSnackbar('Veuillez renseigner un lien actu.epfl.ch ou un ID d\'actualité.', 'error')
        this.fetchNewsLoader = false
        return
      }
      if (this.newsItemProvided.includes('actu.epfl.ch')) {
        await fetch(baseUrlActuEPFLAPI + '?format=json&limit=' + this.newsSearchLimit)
          .then(response => response.json())
          .then(data => {
            console.log(data)
            for (let i = 0; i < data.results.length; i++) {
              if (data.results[i].news_url.concat('/') == this.newsItemProvided) {
                this.fetchedNewsFR = data.results[i]
              }
            }
          })
          .catch((error) => {
            this.fetchNewsLoader = false
            this.showSnackbar('Erreur en récupérant l\'actualité en français. Veuillez regarder les logs.', 'error')
            console.error(error)
          })
      } else {
        await fetch(baseUrlActuEPFLAPI + this.newsItemProvided + '/?lang=fr&format=json')
          .then(response => response.json())
          .then(data => {
            this.fetchedNewsFR = data
          })
          .catch((error) => {
            this.fetchNewsLoader = false
            this.showSnackbar('Erreur en récupérant l\'actualité en français. Veuillez regarder les logs.', 'error')
            console.error(error)
          })
      }

      if (!this.fetchedNewsFR?.id) {
        this.showSnackbar('Aucune actualité trouvée. Essayez de changer la limite de recherche dans les paramètres.', 'warning')
        this.fetchNewsLoader = false
        return
      } else {
        this.newsItemProvided = this.fetchedNewsFR.id
      }

      console.log(this.fetchedNewsFR)
      await fetch(baseUrlActuEPFLAPI + this.fetchedNewsFR.id + '/?lang=en&format=json')
        .then(response => response.json())
        .then(data => {
          this.fetchedNewsEN = data
          this.generateTextFromNews();
          this.fetchNewsLoader = false
          this.showSnackbar('L\'actualité a été chargée avec succès.', 'success')
        })
        .catch((error) => {
          this.fetchNewsLoader = false
          this.showSnackbar('Erreur en récupérant l\'actualité en anglais. Veuillez regarder les logs.', 'error')
          console.error(error)
        })
    },
    /////// GPT ///////
    async askGPT(prompt, article, version) {

      const configuration = new Configuration({
        apiKey: this.keyOpenAI,
      });
      const openai = new OpenAIApi(configuration);
      try {
        const response = await openai.createChatCompletion({
          model: this.GPTModel[version],
          messages: [
            {
              "role": "system",
              "content": prompt
            },
            {
              "role": "user",
              "content": article
            }
          ],
          temperature: this.GPTTemperature[version],
          max_tokens: this.GPTMaxTokens[version],
          top_p: this.GPTTopP[version],
          frequency_penalty: this.GPTFrequencyPenalty[version],
          presence_penalty: this.GPTPresencePenalty[version],
        });

        console.log(response)
        this.showSnackbar('Le texte a été généré avec succès.', 'success')
        this.GPTPromptTwitterFRLoader = false
        this.GPTPromptTwitterENLoader = false
        this.GPTPromptLinkedinLoader = false
        return response.data.choices[0].message.content

      } catch (error) {
        console.error(error);
        this.showSnackbar('Erreur en générant le texte avec l\'API OpenAI. Veuillez regarder les logs.', 'error')
        this.GPTPromptTwitterFRLoader = false
        this.GPTPromptTwitterENLoader = false
        this.GPTPromptLinkedinLoader = false
      }
    },
    async triggerGPTRequest(from, version) {
      if (!this.keyOpenAI || this.keyOpenAI == "null") {
        this.showSnackbar('Veuillez renseigner une clé API OpenAI dans les paramètres.', 'error')
        return
      }
      if (!this.fetchedNewsFR) {
        this.showSnackbar('Veuillez charger une actualité avant de générer un texte.', 'error')
        return
      }
      if (from == "TwitterFR") {
        this.GPTPromptTwitterFRLoader = true
        this.textForTwitterFR = await (await this.askGPT(this.GPTPromptTwitterFR[version], this.removeTags(this.fetchedNewsFR.text), version)).concat("\n" + this.generatedLinkFR)
      } else if (from == "TwitterEN") {
        this.GPTPromptTwitterENLoader = true
        this.textForTwitterEN = await (await this.askGPT(this.GPTPromptTwitterEN[version], this.removeTags(this.fetchedNewsEN.text), version)).concat("\n" + this.generatedLinkEN)
      } else if (from == "Linkedin") {
        this.GPTPromptLinkedinLoader = true
        this.textForLinkedin = await this.askGPT(this.GPTPromptLinkedin[version], this.removeTags(this.fetchedNewsFR.text), version)
      } else {
        this.ImFeellingLuckyLoader = true
        await Promise.all([
          this.textForTwitterFR = await this.askGPT(this.GPTPromptTwitterFR[version], this.removeTags(this.fetchedNewsFR.text), version),
          this.textForTwitterEN = await this.askGPT(this.GPTPromptTwitterEN[version], this.removeTags(this.fetchedNewsEN.text), version),
          this.textForLinkedin = await this.askGPT(this.GPTPromptLinkedin[version], this.removeTags(this.fetchedNewsEN.text), version)
        ])
        this.ImFeellingLuckyLoader = false
      }
    },
    resetGPTConfig(version) {
      this.GPTPromptTwitterFR[version] = defaultGPTPromptTwitterFR[version]
      this.GPTPromptTwitterEN[version] = defaultGPTPromptTwitterEN[version]
      this.GPTPromptLinkedin[version] = defaultGPTPromptLinkedin[version]
      this.GPTModel[version] = defaultGPTModel[version]
      this.GPTTemperature[version] = defaultGPTTemperature[version]
      this.GPTMaxTokens[version] = defaultGPTMaxTokens[version]
      this.GPTTopP[version] = defaultGPTTopP[version]
      this.GPTFrequencyPenalty[version] = defaultGPTFrequencyPenalty[version]
      this.GPTPresencePenalty[version] = defaultGPTPresencePenalty[version]
      this.showSnackbar('La configuration a été réinitialisée par défaut.', 'success')
    },
    /////// GOOGLE SHEET ///////
    async completeSchedule() {
      if (!this.macroUrl) {
        this.showSnackbar('Veuillez renseigner une URL Google Script dans les paramètres.', 'error')
        return
      }
      if (!this.fetchedNewsFR) {
        this.showSnackbar('Veuillez charger une actualité avant de générer un texte.', 'error')
        return
      }

      const data = {
        title: this.fetchedNewsEN.title,
        url: this.fetchedNewsEN.news_url,
        category: this.fetchedNewsEN.channel.name,
        date: new Date(this.fetchedNewsEN?.publish_date).toLocaleDateString("fr-CH", { day: "2-digit", month: "2-digit", year: "numeric" }),
        complete: true,
      };

      const queryParams = new URLSearchParams(data);
      const urlWithParams = `${this.macroUrl}?${queryParams}`;
      window.open(urlWithParams, "_blank");
    },
    generateConfig() {
      const config = {
        generatedLinkFR: this.generatedLinkFR,
        generatedLinkEN: this.generatedLinkEN,
        textForTwitterFR: this.textForTwitterFR,
        textForTwitterEN: this.textForTwitterEN,
        textForLinkedin: this.textForLinkedin,
      }
      const configCompressed = LZString.compressToEncodedURIComponent(JSON.stringify(config))
      const url = window.location.origin + window.location.pathname + 'edit/' + this.newsItemProvided + '/load/' + configCompressed
      // copy url to clipboard
      navigator.clipboard.writeText(url)
      this.showSnackbar('La configuration a été copiée dans le presse-papier.', 'success')
    },
  }
};
</script>